import { template as template_88a2ac386d744e4095329a5eafcc8f93 } from "@ember/template-compiler";
const FKLabel = template_88a2ac386d744e4095329a5eafcc8f93(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
