import { template as template_59eb663541c64f8b864ccb35d6f5788e } from "@ember/template-compiler";
const FKControlMenuContainer = template_59eb663541c64f8b864ccb35d6f5788e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
